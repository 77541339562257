import { defineStore } from 'pinia';

export const useScrollStore = defineStore('scrollStore', {
  state: () => ({
    disableScroll: false,
  }),
  actions: {
    setDisableScroll(value: boolean) {
      this.disableScroll = value;
    },
  },
});
